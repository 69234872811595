<button [type]="type" [id]="gtmId" [disabled]="isDisabled" class="hf-button" [ngClass]="{'hf-button-large hf-button-text-large': buttonSize === 'large', 
    'hf-button-normal hf-button-text-normal': buttonSize === 'normal',
    'hf-button-medium hf-button-text-medium': buttonSize === 'medium',
    'hf-button-small hf-button-text-small': buttonSize === 'small',
    'hf-button-extrasmall hf-button-text-small': buttonSize === 'extrasmall',
    'hf-button-primary': buttonStyle === 'primary',
    'hf-button-primary-click': (isClicked === true && buttonStyle === 'primary'),
    'hf-button-secondary': buttonStyle === 'secondary',
    'hf-button-secondary-click': (isClicked === true && buttonStyle === 'secondary'),
    'hf-button-tertiary': buttonStyle === 'tertiary',
    'hf-button-tertiary-click': (isClicked === true && buttonStyle === 'tertiary')}" (click)="isClicked = true">
    @if (buttonType === 'icononly') {
    <img [id]="gtmId" [src]='iconUrl' [alt]="iconAlt" [title]="iconTitle" loading="lazy" />
    } @else {
    @if (iconStyle === 'iconleft') {
    <img [id]="gtmId" class="iconleft" [src]='iconUrl' [alt]="iconAlt" [title]="iconTitle" loading="lazy" />
    }
    <span [id]="gtmId">{{labelText}}</span>
    @if (iconStyle === 'iconright') {
    <img [id]="gtmId" class="iconright" [src]='iconUrl' [alt]="iconAlt" [title]="iconTitle" loading="lazy" />
    }
    }
</button>