import { Route } from '@angular/router';

export const appRoutes: Route[] = [
    {
        path: 'blog',
        loadChildren: () =>
            import('./pages/blog/blog.module').then((m) => m.BlogPageModule),
    },
    {
        path: '',
        loadChildren: () => import('./components/master/master.routes').then(m => m.routes)
    },
    {
        path: '**',
        loadComponent: () => import('./components/master/not-found/not-found.component').then(m => m.NotFoundComponent)
    },
];
